import { useEffect, useState, useRef } from "react";
import {
  MainWrapper,
  MainPDFWrapper,
  SvgLineContainer,
  BackspaceContainer,
  HeaderWrapper,
  HeaderTitle,
  CenterPaneContainer,
  LastPaneContainer,
  FirstPaneContainer,
  LoaderContainer
} from "./PdfViewerPage.styled";
import PdfCommentPanel from "../../modules/PdfCommentPanel/PdfCommentPanel";
import PdfViewer from "../../modules/PdfViewer/PdfViewer";
import PdfThumbnailViewer from "../../components/PdfThumbnailViewer/PdfThumbnailViewer";
import Button from "../../components/Button/Button";
import speechFormat from "../../helpers/speechFormat";
import BackspaceIcon from "../../assets/svgs/BackspaceIcon";
import { useNavigate } from "react-router-dom";
import { downloadSharepointPdf, getRedactionReview, submitRedactionReview } from "../../apis/ApiServerRequests";
import { useParams } from "react-router-dom";
import SkeletonLoader from "../../components/SkeletonLoader/SkeletonLoader";
import SuccessICF from "../../modules/SuccessICF/SuccessICF";
import useKeyPress from "../../hooks/useKeyPress";
import SuccessPopup from "../../components/SuccesPopup/SuccessPopup";

const pdfFileurl = `${process.env.PUBLIC_URL}/protocol.pdf`

const PdfViewerPage = () => {
  const navigate = useNavigate();

  const { pdfId } = useParams();

  const [annotations, setAnnotations] = useState([])
  const [annotationItems, setAnnotationItems] = useState([]);

  const [selectedCommentId, setSelectedCommentId] = useState(null);
  const [selectedAnnotationId, setSelectedAnnotationId] = useState(null);

  const [annotationPosition, setAnnotationPosition] = useState([]);
  const [commentPosition, setCommentPosition] = useState([]);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  const [loading, setLoading] = useState(false);
  const [generated, setGenerated] = useState(false);
  const [downloadUrl, setDownloadUrl] = useState(null);
  const [progress, setProgress] = useState({
    filename: "",
    total_mappings: 21,
    mappings_processed: 0,
    estimated_time: 5,
  });

  const [pdfFile, setPdfFile] = useState(null);

  const handleAnnotationClick = (id) => {
    setSelectedCommentId(id);
    setSelectedAnnotationId(id);
  };

  // Function to update the status based on commentId
  const updateAnnotationStatus = (commentId) => {
    setAnnotations((prevAnnotations) =>
      prevAnnotations.map((annotation) =>
        annotation.id === commentId
          ? { ...annotation, status: annotation.status === 0 ? 1 : 0 } // Toggle status as an example
          : annotation
      )
    );
  };

  const handleCommentClick = (comment) => {
    if (comment) {
      updateAnnotationStatus(comment?.id);
      setSelectedCommentId(comment?.id);
      setSelectedAnnotationId(comment?.id);
      setPageNumber(comment?.page);
    }
    setCommentPosition([])
  };

  const handleThumbnailClick = (pageNum) => {
    setPageNumber(pageNum);
  };

  useEffect(() => {
    if (commentPosition.length > 0) {
      // Extract IDs from the commentPosition
      const commentIds = commentPosition.map(pos => +pos.id);
      // Filter items based on the extracted IDs
      const matchingItems = annotations.filter(item => commentIds.includes(item.id));

      // Set the filtered matching items in the annotationItems state
      setAnnotationItems(matchingItems);
    }
}, [commentPosition, annotations])


  useEffect(() => {
    if (pdfId) {
      const loadPdfFile = async () => {
        try {
          const pdfDetails = await getRedactionReview(pdfId); // Replace with your file path
          setAnnotations(pdfDetails?.data?.redactions)
          const response = await downloadSharepointPdf(pdfId)
          const pdfBlob = new Blob([response.data], { type: 'application/pdf' })
          const url = URL.createObjectURL(pdfBlob);
          setPdfFile(url);
        } catch (error) {
          console.error('Failed to load the PDF file', error);
        }
      };

      loadPdfFile();
    }
  }, [pdfId]);

  const pdfViewerRef = useRef(null);
  const [pdfViewerWidth, setPdfViewerWidth] = useState(0);

  useEffect(() => {
    if (pdfViewerRef.current) {
      const width = pdfViewerRef.current.offsetWidth;
      setPdfViewerWidth(width + 80);
    }
  }, [annotationPosition, pdfViewerRef]);

  const onBack = () => {
    navigate("/");
  };

  const handleGenerated = (val) => {
    // setGenerated(false)
    // setProgress({
    //   filename: "",
    //   total_mappings: 21,
    //   mappings_processed: 0,
    //   estimated_time: 5,
    // })
    navigate('/redact')
  };

  const handleRedactionSubmission = () => {
    setLoading(true);
    submitRedactionReview(pdfId, annotations).then((response) => {
        setDownloadUrl(response.data.public_url)
        setProgress({
          filename: response.data.filename,
          total_mappings: 21,
          mappings_processed: 0,
          estimated_time: 5,
        })
        setLoading(false)
        setGenerated(true)
    }).catch((err) => {
        console.log("error during redaction review submission ", err)
    })
  }

  useKeyPress("G", ["altKey"], () => handleRedactionSubmission());
  useKeyPress("backspace", [], () => onBack());


  if (!pdfFile) {
    return (
      <div style={{ width: "100%" }}>
        <LoaderContainer >
          <SkeletonLoader height={"100%"} />
        </LoaderContainer>
      </div>
    )
  }

  

  return (
    <>
    {loading && (
        <SuccessPopup
            msg_processing={"Generating Doc, Please wait..."}
            onClose={() => { }}
            loading={true}
        />
    )}
      {generated && !loading && <SuccessICF
        handleGenerated={handleGenerated}
        docTitle={progress?.filename}
        totalMapping={progress?.total_mappings}
        resolvedMapping={progress?.mappings_processed}
      ></SuccessICF>}
      {!generated && <MainWrapper>
        <FirstPaneContainer>
          <BackspaceContainer>
            <Button
              icon={<BackspaceIcon />}
              type={"ghost"}
              accessKeyText={"[BACKSPACE]"}
              onClick={onBack}
              iconStyle={{ marginRight: "4px" }}
              data-speech={speechFormat({
                key: "Backspace",
                action: "go back",
                item: "to Dashboard",
              })}
              buttonStyle={{ paddingTop: "0px" }}
            />

          </BackspaceContainer>
          {/* Left panel: PDF Thumbnail Viewer */}
          <PdfThumbnailViewer
            file={pdfFile}
            numPages={numPages}
            onThumbnailClick={handleThumbnailClick}
            currentPage={pageNumber}
          />
        </FirstPaneContainer>

        <CenterPaneContainer>
          <HeaderWrapper>
            <HeaderTitle>Review Redaction</HeaderTitle>
            <Button
              disabled={false}
              text={"Create Final Doc"}
              type={"tertiary"}
              accessKeyText={"[Alt + G]"}
              onClick={() => handleRedactionSubmission()}
              data-speech={speechFormat({
                key: "Alt + G",
                action: "redact",
                item: "Doc",
              })}
            />
          </HeaderWrapper>
          {/* center panel: PDF Viewer */}
          <MainPDFWrapper ref={pdfViewerRef}>
            <PdfViewer
              file={pdfFile}
              annotations={annotationItems}
              setAnnotations={setAnnotationItems}
              onAnnotationClick={handleAnnotationClick}
              setAnnotationPosition={setAnnotationPosition}
              selectedAnnotationId={selectedAnnotationId} // Pass selected annotation
              setNumPages={setNumPages}
              pageNumber={pageNumber}
            />
          </MainPDFWrapper>

        </CenterPaneContainer>


        <LastPaneContainer>
          <PdfCommentPanel
            comments={annotations.sort((a, b) => a.page - b.page)}
            selectedCommentId={selectedCommentId}
            onCommentClick={handleCommentClick}
            handleCommentSelection={handleAnnotationClick}
            setCommentPosition={setCommentPosition}
            setPageNumber={setPageNumber}
            pageNumber={pageNumber}
          />
        </LastPaneContainer>


        {/* SVG Layer for drawing lines */}
        <SvgLineContainer>
          {commentPosition?.length > 0 && annotationPosition.map((annotation, index) => {
            if (index < commentPosition?.length) {
              return (
                <>
                  <line
                    key={`line-horizontal-${annotation.id}`} // Add a unique key for horizontal line
                    x1={annotation.x + annotation.width / 2} // Center of the annotation bounding box
                    y1={annotation.y + annotation.height} // Bottom edge of the annotation
                    x2={pdfViewerWidth} // Right end of the PDF viewer
                    y2={annotation.y + annotation.height} // Same Y as the annotation
                    stroke={commentPosition[index]?.borderColor}
                    strokeWidth="2"
                    strokeDasharray="5,5" // Dotted line style
                    markerEnd="url(#arrowhead)"
                  />

                  {/* Vertical line from the right edge of the PDF viewer to the comment panel */}
                  <line
                    key={`line-vertical-${annotation.id}`} // Add a unique key for vertical line
                    x1={pdfViewerWidth} // Right end of the PDF viewer
                    y1={annotation.y + annotation.height} // Same Y as the previous line
                    x2={commentPosition[index]?.x} // Center of the comment position
                    y2={commentPosition[index]?.y} // Y of the top-left corner of the comment div
                    stroke={commentPosition[index]?.borderColor}
                    strokeWidth="2"
                    strokeDasharray="5,5" // Dotted line style
                    markerEnd="url(#arrowhead)"
                  />
                </>
              );
            }
            return null; // Return null if the annotation is not on the current page
          })}
        </SvgLineContainer>
      </MainWrapper>}
    </>

  );
};

export default PdfViewerPage;