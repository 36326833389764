import React from "react";

const DockBottomIcon = () => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" height="24px" viewBox="0 -960 960 960" width="24px" fill="#9E9E9E"><path d="M194-88q-45 0-75.5-30.5T88-194v-572q0-45 30.5-75.5T194-872h572q45 0 75.5 30.5T872-766v572q0 45-30.5 75.5T766-88H194Zm0-226v120h572v-120H194Zm0-106h572v-346H194v346Zm0 106v120-120Z"/>
        </svg>
    );
};

export default DockBottomIcon;


