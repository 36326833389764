import styled from "styled-components";

export const ThumbnailContainer = styled.div`
    overflow-y: auto;
    border: ${(props) => `1px solid ${props.theme.color_theme.color}`};
    padding: 10px;
    margin-left: 10px;
`;


export const PageContainer = styled.div`
    position: relative;
    margin-bottom: 20px;
    cursor: pointer;
    border: ${({ border }) => border};
    background-color: transparent;
    text-align: center;
    color: white;
`;

export const ThumbnailOverlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.1); 
    z-index: 1;
`;

export const BottomText = styled.div`
    margin-top: 5px; 
    font-size: 12px;
    color: ${(props) => props.theme.color_theme.color}
`;