import styled from "styled-components";


export const MainWrapper = styled.div`
  padding: 10px;
  overflow-y: auto;
`;

export const CommentContainer = styled.div`
  padding: 10px;
  margin: 10px 0;
  background-color: ${({ backgroundColor }) => backgroundColor};
  border: 1px solid #ccc;
  border-color: ${({ borderColor }) => borderColor};
  border-radius: 4px;
  display: flex;
  cursor: pointer;
  justify-content: space-between;
`;

export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.p`
  margin: 0px;
  padding: 0px;
  font-weight: 500;
`;

export const ListTitle = styled.p`
  margin: 0px;
  margin-left: 15px;
  padding: 0px;
  opacity: 0.4;
  font-weight: 500;
  font-size: 10px;
`;

export const CommentText = styled.p`
  font-weight: 500;
  margin: 0px;
  padding: 0px
  font-family: Segoe UI;
  font-size: ${(props) => props.theme.font_delta + 13}px;
`;


export const PaginationContainer = styled.div`
  margin: 14px 0px;
  display: flex;
  justify-content: center;
`;


export const OptionContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  cursor: pointer;
`;
