import React from "react";
import { useTheme } from 'styled-components';

const AcceptIcon = () => {
    const theme = useTheme();
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M9.71732 15.2154L16.1798 8.75286L14.8965 7.46953L9.71732 12.6487L7.10482 10.0362L5.82148 11.3195L9.71732 15.2154ZM11.0007 20.1654C9.7326 20.1654 8.54093 19.9247 7.42565 19.4435C6.31037 18.9622 5.34023 18.3091 4.51523 17.4841C3.69023 16.6591 3.03711 15.689 2.55586 14.5737C2.07461 13.4584 1.83398 12.2668 1.83398 10.9987C1.83398 9.73064 2.07461 8.53898 2.55586 7.4237C3.03711 6.30842 3.69023 5.33828 4.51523 4.51328C5.34023 3.68828 6.31037 3.03516 7.42565 2.55391C8.54093 2.07266 9.7326 1.83203 11.0007 1.83203C12.2687 1.83203 13.4604 2.07266 14.5757 2.55391C15.6909 3.03516 16.6611 3.68828 17.4861 4.51328C18.3111 5.33828 18.9642 6.30842 19.4454 7.4237C19.9267 8.53898 20.1673 9.73064 20.1673 10.9987C20.1673 12.2668 19.9267 13.4584 19.4454 14.5737C18.9642 15.689 18.3111 16.6591 17.4861 17.4841C16.6611 18.3091 15.6909 18.9622 14.5757 19.4435C13.4604 19.9247 12.2687 20.1654 11.0007 20.1654ZM11.0007 18.332C13.0479 18.332 14.7819 17.6216 16.2027 16.2008C17.6236 14.7799 18.334 13.0459 18.334 10.9987C18.334 8.95148 17.6236 7.21745 16.2027 5.79661C14.7819 4.37578 13.0479 3.66536 11.0007 3.66536C8.95343 3.66536 7.2194 4.37578 5.79857 5.79661C4.37773 7.21745 3.66732 8.95148 3.66732 10.9987C3.66732 13.0459 4.37773 14.7799 5.79857 16.2008C7.2194 17.6216 8.95343 18.332 11.0007 18.332Z" fill={theme.color_theme.primaryColor} />
        </svg>
    );
};

export default AcceptIcon;
