import React from "react";
import { useTheme } from 'styled-components';

const RejectIcon = () => {
    const theme = useTheme();
    return (
        <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M7.99935 15.168L10.9993 12.168L13.9993 15.168L15.166 14.0013L12.166 11.0013L15.166 8.0013L13.9993 6.83464L10.9993 9.83463L7.99935 6.83464L6.83268 8.0013L9.83268 11.0013L6.83268 14.0013L7.99935 15.168ZM10.9993 19.3346C9.84657 19.3346 8.76324 19.1159 7.74935 18.6784C6.73546 18.2409 5.85352 17.6471 5.10352 16.8971C4.35352 16.1471 3.75977 15.2652 3.32227 14.2513C2.88477 13.2374 2.66602 12.1541 2.66602 11.0013C2.66602 9.84852 2.88477 8.76519 3.32227 7.7513C3.75977 6.73741 4.35352 5.85547 5.10352 5.10547C5.85352 4.35547 6.73546 3.76172 7.74935 3.32422C8.76324 2.88672 9.84657 2.66797 10.9993 2.66797C12.1521 2.66797 13.2355 2.88672 14.2493 3.32422C15.2632 3.76172 16.1452 4.35547 16.8952 5.10547C17.6452 5.85547 18.2389 6.73741 18.6764 7.7513C19.1139 8.76519 19.3327 9.84852 19.3327 11.0013C19.3327 12.1541 19.1139 13.2374 18.6764 14.2513C18.2389 15.2652 17.6452 16.1471 16.8952 16.8971C16.1452 17.6471 15.2632 18.2409 14.2493 18.6784C13.2355 19.1159 12.1521 19.3346 10.9993 19.3346ZM10.9993 17.668C12.8605 17.668 14.4368 17.0221 15.7285 15.7305C17.0202 14.4388 17.666 12.8624 17.666 11.0013C17.666 9.14019 17.0202 7.5638 15.7285 6.27214C14.4368 4.98047 12.8605 4.33464 10.9993 4.33464C9.13824 4.33464 7.56185 4.98047 6.27018 6.27214C4.97852 7.5638 4.33268 9.14019 4.33268 11.0013C4.33268 12.8624 4.97852 14.4388 6.27018 15.7305C7.56185 17.0221 9.13824 17.668 10.9993 17.668Z" fill={theme.color_theme.primaryColor} />
        </svg>

    );
};

export default RejectIcon;
