import styled from "styled-components";

export const MainWrapper = styled.div`
  display: flex;
  width: 100vw;
  margin-top: 10px;
  flex-direction: column;
`;

export const BackspaceContainer = styled.div`
  display: flex;
  width: 100%;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height : 36px;
`;

export const HeaderTitleWrapper = styled.div`
  width: 100%; 
  display: flex;
  justify-content: space-between;
`;

export const HeaderTitle = styled.div`
  font-weight: 500;
  font-size: 12px;
  display: flex;
  align-items: center;
`;

export const EditorWrapper = styled.div`
  width: 100%;
  margin-top: 10px;
  display: ${props => (props.sideAlign ? 'flex' : 'block')};
`;

export const DocWrapper = styled.div`
  flex: 1; 
  // height: ${props => (props.sideAlign ? '620px' : 'auto')};
  width:  ${props => (props.sideAlign ? '100%' : '50%')};
`;


export const MainPDFWrapper = styled.div`
  height: ${props => (props.sideAlign ? '230px' : 'auto')};
  padding: 0px 20px 0px 20px;
  overflow: scroll;
  width: -webkit-fill-available;
`;


export const SourceWrapper = styled.div`
  display: flex;
  height: ${props => (props.sideAlign ? `${props.divHeight - 100}px` : `${(props.divHeight/2) - 80}px`)};
  overflow: auto;
`;

export const ThumbnailWrapper = styled.div`
  width: 250px;
  height: ${props => (props.sideAlign ? `${props.divHeight - 100}px` : `${(props.divHeight/2) - 80}px`)};
  overflow: auto;
`;


export const TabContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;


export const TabInnerContainer = styled.div`
  margin: 0px 12px;
  display: flex;
  align-items: center;
  gap: 20px;
`;


export const TabRightIconWrapper = styled.div`
  margin-right: 12px;
  cursor: pointer;
`;


export const ButtonPanelContainer = styled.div`
  display: flex; 
  cursor: pointer;
  background-color: ${props => (props.active ? '#f0f0f0' : 'transparent')};
  border-radius: 4px;
  padding: 5px 
`;