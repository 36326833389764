import "./App.css";
import * as Sentry from "@sentry/react";
import ProtectRoute from "./ProtectRoute/ProtectRoute";
import { Route, Routes } from "react-router-dom";
import { MsalProvider } from "@azure/msal-react";
import { PublicClientApplication } from "@azure/msal-browser";
import SignInPage from "./pages/SignInPage/SignInPage";
import useSpeechSynthesis from "./hooks/useSpeechSynthesis";
import DashboardPage from "./pages/DashboardPage/DashboardPage";
import AuthCallbackPage from "./pages/AuthCallbackPage/AuthCallbackPage";
import { AZ_APP_CALLBACK, AZ_APP_CLIENT_ID, AZ_TENANT_ID } from "./configs";
import "react-loading-skeleton/dist/skeleton.css";

const msalConfig = {
  auth: {
    clientId: AZ_APP_CLIENT_ID,
    authority: `https://login.microsoftonline.com/${AZ_TENANT_ID}`,
    redirectUri: AZ_APP_CALLBACK(),
  },
};

const msalInstance = new PublicClientApplication(msalConfig);
const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

function App() {
  useSpeechSynthesis();

  return (
    <MsalProvider instance={msalInstance}>
      <SentryRoutes>
        <Route path="/signin" element={<SignInPage />} />
        <Route path="/callback" component={AuthCallbackPage} />

        <Route
          path="/"
          element={
            <ProtectRoute>
              <DashboardPage />
            </ProtectRoute>
          }
        >
          <Route path="generate" element={<DashboardPage />} />
          <Route path="format" element={<DashboardPage />} />
          <Route path="redact" element={<DashboardPage />} />
          <Route path="logs" element={<DashboardPage />} />
          <Route path="review/pdf/:pdfId" element={<DashboardPage />} />
          <Route path="review/format/doc" element={<DashboardPage />} />
          <Route path="review/generate/doc" element={<DashboardPage />} />
        </Route>
      </SentryRoutes>
    </MsalProvider>
  );
}

export default App;
