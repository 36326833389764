import React from 'react';
import { Document, Page } from 'react-pdf';
import { MainWrapper, PDFBackgroundWrapper } from "./PdfViewer.styled";
import AnnotationOverlay from '../../components/AnnotationOverlay/AnnotationOverlay'; // Your annotation overlay component


const PdfViewer = ({ file, setNumPages, pageNumber, annotations, setAnnotations, selectedAnnotationId, onAnnotationClick, setAnnotationPosition, isAnnotationOverlay = true, scalorFactor = 0.78  }) => {  
  
    const onDocumentLoadSuccess = ({ numPages }) => {
      setNumPages(numPages);
    };

    const addAnnotation = (annotation) => {
        setAnnotations((prevAnnotations) => [...prevAnnotations, annotation]);
    };
  
    return (
      <MainWrapper style={{ width: '100%' }} >
        <PDFBackgroundWrapper>
          <Document
            file={file}
            onLoadSuccess={onDocumentLoadSuccess}
          >
            <Page 
              pageNumber={pageNumber} 
              renderTextLayer={false}
              renderAnnotationLayer={false} 
              scale={scalorFactor}
            />
          </Document>
        </PDFBackgroundWrapper>
        
        {/* Pass the annotations and current page number to the overlay */}
        {isAnnotationOverlay && <AnnotationOverlay
          annotations={annotations}
          pageNumber={pageNumber}
          selectedAnnotationId={selectedAnnotationId} // Pass selected annotation id
          onAnnotationClick={onAnnotationClick}
          onAddAnnotation={addAnnotation}
          setAnnotationPosition={setAnnotationPosition}
          scale={scalorFactor}
          color={'rgb(46, 152, 102, 0.15)'}
        />}
      </MainWrapper>
    );
};





export default PdfViewer