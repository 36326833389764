import React, { useRef, useEffect } from 'react';
import { Document, Page } from 'react-pdf';
import { ThumbnailContainer, PageContainer, ThumbnailOverlay, BottomText } from "./PdfThumbnailViewer.styled"
import { useTheme } from 'styled-components';

const PdfThumbnailViewer = ({ file, numPages, onThumbnailClick, currentPage, ...props }) => {
  const thumbnailRefs = useRef([]);
  const containerRef = useRef();
  const theme = useTheme();

  useEffect(() => {
    const currentThumbnail = thumbnailRefs.current[currentPage - 1];

    if (currentThumbnail) {
      const container = containerRef.current;
      const scrollTop = currentThumbnail.offsetTop - container.offsetTop;

      container.scrollTo({
        top: scrollTop,
        behavior: 'smooth',
      });
    }
  }, [currentPage]);

  return (
    <ThumbnailContainer ref={containerRef} props>
      <Document file={file}>
        {Array.from({ length: numPages }, (_, index) => index + 1).map((pageNum) => (
          <PageContainer
            key={pageNum}
            onClick={() => onThumbnailClick(pageNum)}
            ref={(el) => (thumbnailRefs.current[pageNum - 1] = el)}
            border={currentPage === pageNum ? `2px solid ${theme.color_theme.color}` : 'none'}
          >
            <Page
              pageNumber={pageNum}
              width={140} // Smaller width for thumbnails
              renderTextLayer={false}
              renderAnnotationLayer={false}
            />

            {/* Overlay for inactive pages */}
            {currentPage !== pageNum && (
              <ThumbnailOverlay />
            )}

            {/* Display the page number below the thumbnail */}
            <BottomText   {...props}
            >
              Page {pageNum}
            </BottomText>
          </PageContainer>
        ))}
      </Document>
    </ThumbnailContainer>
  );
};

export default PdfThumbnailViewer