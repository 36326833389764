import React, { useRef, useState, useEffect } from 'react';


const AnnotationOverlay = ({ color, annotations, onAddAnnotation, selectedAnnotationId, setAnnotationPosition, onAnnotationClick, pageNumber, scale }) => {
  const overlayRef = useRef(null);

  useEffect(() => {
    if (overlayRef.current) {
      const annotationElements = overlayRef.current.querySelectorAll('[data-id]'); // Select all annotation elements
      const positions = Array.from(annotationElements).map(annotationElement => {
        const rect = annotationElement.getBoundingClientRect();
        return {
          x: rect.left + rect.width / 2, // Center X of the bounding box
          y: rect.top, // Top Y of the bounding box
          width: rect.width,
          height: rect.height,
        };
      });

      setAnnotationPosition(positions); // Update state with array of positions
    }
  }, [annotations, setAnnotationPosition, pageNumber]);

  return (
    <div
      ref={overlayRef}
      style={{
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 1,
        pointerEvents: 'auto', // Enable interaction on the overlay
      }}
    >
      {/* Render all saved annotations */}
      {annotations
        .filter((annotation) => annotation.page === pageNumber)
        .map((annotation) => (
          <div
            key={annotation.id}
            data-id={annotation.id}
            onClick={() => onAnnotationClick(annotation.id)} // Pass the annotation id on click
            style={{
              position: 'absolute',
              left: `${annotation.x * scale}px`, // Adjust position based on scale
              top: `${annotation.y * scale}px`,  // Adjust position based on scale
              width: `${Math.abs(annotation.width) * scale}px`, // Adjust width based on scale
              height: `${Math.abs(annotation.height) * scale}px`, // Adjust height based on scale
              backgroundColor: (selectedAnnotationId === annotation.id &&  annotation.status) ? 'rgb(46, 152, 102, 0.3)' : annotation.status ? color : "rgb(0, 0, 0, 0.25)",
              cursor: 'pointer',
              border: selectedAnnotationId === annotation.id ? '1px solid rgb(46, 152, 102, 0.3)': ''
            }}
          />
        ))}
    </div>
  );
};


export default AnnotationOverlay;
