import styled from "styled-components";


export const MainWrapper = styled.div`
  display: flex;
  width: 100vw;
  max-height: 720px;
  margin-top: 10px;
`;

export const MainPDFWrapper = styled.div`
  height: 650px;
  padding: 0px 20px 0px 20px;
`;


export const CenterPaneContainer = styled.div`
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  max-height: 550px;
  gap: 10px;
`;

export const LastPaneContainer = styled.div`
  width: 336px;
  height: 660px;
  display: flex;
  flex-direction: column;
  border-left: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
`;

export const FirstPaneContainer = styled.div`
  width: 227px;
  height: 665px;
  gap: 10px;
  display: flex;
  flex-direction: column;
`;

export const SvgLineContainer = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  pointer-events: none; // Make sure SVG doesn't block interaction
`;


export const BackspaceContainer = styled.div`
  display: flex;
  width: 100%;
`;


export const HeaderWrapper = styled.div`
  display: flex;
  padding: 0px 20px 0px 20px;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;


export const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const HeaderTitle = styled.p`
  margin: 0px;
  padding: 0px;
  font-weight: 500;
`;

export const ListTitle = styled.p`
  margin: 0px;
  margin-left: 15px;
  padding: 0px;
  opacity: 0.4;
  font-weight: 500;
  font-size: 10px;
`;

export const LoaderContainer = styled.div`
  height: -webkit-fill-available; /* For WebKit-based browsers */
  height: -moz-available; /* For Mozilla Firefox */
  height: fill-available; /* For other modern browsers */
`;



